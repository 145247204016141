import React from "react"

import LayoutPagesCapture from "../../../../components/LayoutPagesCapture"
import SEO from "../../../../components/seo"

import * as S from "../../../../components/PagesCapture/styled"

const EbookPalavrasChavesNegativasPage = () => {
  return (
    <LayoutPagesCapture>
      <SEO
        title="Ebook - Palavras Chaves Negativas"
        description="Muito Obrigado por baixar o Ebook contendo as principais palavras chaves negativas para você utilizar em suas campanhas de Google Ads."
      />
      <S.PagesCaptureThanksBlockDescriptionFull>
        <S.PagesCaptureThanksTitle>
          Quase lá! Em 5 minutos o link do seu Ebook já estará no seu Email...
        </S.PagesCaptureThanksTitle>
        <S.PagesCaptureThanksText>
          Enquanto aguarda, Veja esse vídeo que vou te dar uma oportunidade de
          conhecer a mentoria e ter um acompanhamento mais próximo em seu
          negócio digital.
        </S.PagesCaptureThanksText>
        <S.PagesCaptureThanksIframeContainer>
          <S.PagesCaptureThanksIframe
            src="https://www.youtube.com/embed/91yq4RbCpbw"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></S.PagesCaptureThanksIframe>
        </S.PagesCaptureThanksIframeContainer>
        <S.PagesCaptureDescriptionLink
          href="https://bit.ly/ved-obrigado-palavras-chaves-negativas"
          target="_blank"
        >
          Quero mais detalhes!
        </S.PagesCaptureDescriptionLink>
      </S.PagesCaptureThanksBlockDescriptionFull>
    </LayoutPagesCapture>
  )
}

export default EbookPalavrasChavesNegativasPage
